import { BlogPost } from '../types/BlogPost';

const POSTS_DATA_PATH = '/content/posts/posts-data.json';
const MARKDOWN_PATH = '/content/posts';

export const blogService = {
  async getAllPosts(): Promise<BlogPost[]> {
    try {
      const response = await fetch(POSTS_DATA_PATH);
      const data = await response.json();
      const posts = Array.isArray(data) ? data : (data.posts || []);
      return posts.sort((a: BlogPost, b: BlogPost) =>
        (new Date(a.publishDate) > new Date(b.publishDate) ? -1 : 1)
      );
    } catch (error) {
      console.error('Error fetching posts:', error);
      return [];
    }
  },

  async getPostBySlug(slug: string): Promise<BlogPost | null> {
    try {
      const metadataResponse = await fetch(POSTS_DATA_PATH);
      const data = await metadataResponse.json();
      const posts = Array.isArray(data) ? data : (data.posts || []);
      const postMetadata = posts.find((post: BlogPost) => post.slug === slug);

      if (!postMetadata) {
        console.log('Post metadata not found for slug:', slug);
        return null;
      }

      const contentResponse = await fetch(`${MARKDOWN_PATH}/${slug}.md`);
      if (!contentResponse.ok) {
        console.log('Markdown file not found for slug:', slug);
        throw new Error('Post content not found');
      }
      const content = await contentResponse.text();
      return {
        ...postMetadata,
        content
      };
    } catch (error) {
      console.error('Error fetching blog post:', error);
      return null;
    }
  }
};
