import React from 'react';
import { Helmet } from 'react-helmet-async';
import { LoanStep, LoanFeature } from '../types/LoanProcess';

const LOAN_FEATURES: LoanFeature[] = [
  {
    id: 1,
    title: '手續簡便',
    description: '輕鬆簡單辦理'
  },
  {
    id: 2,
    title: '貸款額度高',
    description: '最高達房屋估值80%'
  },
  {
    id: 3,
    title: '還款時間靈活',
    description: '可申請提前還款'
  },
  {
    id: 4,
    title: '利率優惠',
    description: '月息最低0.5%起'
  },
  {
    id: 5,
    title: '放款時間快',
    description: '最快3天內放款'
  },
  {
    id: 6,
    title: '貸款申請保密',
    description: '保障個資與隱私'
  }
];

const LOAN_STEPS: LoanStep[] = [
  {
    id: 1,
    title: '加入LINE好友',
    description: '拿出手機使用LINE「電話搜尋」 0912382387，將 "Harry Lin" 設為好友。'
  },
  {
    id: 2,
    title: '提供債權抵押文件',
    description: '請將抵押文件掃描或拍照後，傳送至LINE對話窗中，將有專人為您審查。'
  },
  {
    id: 3,
    title: '貸款審查與簽約',
    description: '日生金審查貸款資格合格後，進行貸款簽約。'
  },
  {
    id: 4,
    title: '貸款撥放',
    description: '銀行撥放借貸金額至您指定的銀行帳戶，確認貸款款項入帳，這樣就完成囉 ~ 提醒您，請依照貸款合約約定按時還款。'
  }
];

const REQUIRED_DOCUMENTS = [
  '有效的中華民國國民身分證',
  '申辦辦理抵押的不動產所有權狀',
  '貸款申請其他相關資料'
];

const LoanList: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>您的貸款選擇</title>
      </Helmet>
      {/* 特色區塊 */}
      <section className="mb-16">
        <h2 className="text-3xl font-bold text-center mb-8">貸款特色</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {LOAN_FEATURES.map((feature) => (
            <div key={feature.id} className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* 申請步驟 */}
      <section className="mb-16">
        <h2 className="text-3xl font-bold text-center mb-8">申請步驟</h2>
        <div className="space-y-8">
          {LOAN_STEPS.map((step) => (
            <div key={step.id} className="flex items-start">
              <div className="bg-blue-600 text-white rounded-full w-8 h-8 flex items-center justify-center flex-shrink-0 mt-1">
                {step.id}
              </div>
              <div className="ml-4">
                <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* 所需文件 */}
      <section className="mb-16">
        <h2 className="text-3xl font-bold text-center mb-8">申請所需資料</h2>
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <ul className="list-disc list-inside space-y-2">
            {REQUIRED_DOCUMENTS.map((doc, index) => (
              <li key={index} className="text-gray-700">{doc}</li>
            ))}
          </ul>
        </div>
      </section>

      {/* 立即申請按鈕 */}
      <div className="text-center">
        <button className="bg-blue-600 text-white px-8 py-3 rounded-lg text-xl font-semibold hover:bg-blue-700 transition-colors">
          立即借貸
        </button>
      </div>
    </div>
  );
};

export default LoanList;
