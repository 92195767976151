import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Navigation from './components/Navigation';
import RealEstateList from './components/RealEstateList';
import LoanList from './components/LoanApply';
import BlogList from './components/BlogList';
import BlogPost from './components/BlogPost';
import { ROUTES } from './config/routes';
import Home from './components/Home';

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Router>
        <div className="min-h-screen bg-gray-100">
          <Navigation />
          <Routes>
            <Route path={ROUTES.HOME} element={<Home />} />
            <Route path={ROUTES.INVEST} element={<RealEstateList />} />
            <Route path={ROUTES.LOAN} element={<LoanList />} />
            <Route path={ROUTES.BLOG} element={<BlogPost />} />
            <Route path={ROUTES.BLOGLIST} element={<BlogList />} />
    
          </Routes>
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default App;
