import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { RealEstate } from '../types/RealEstate';
import { API_CONFIG } from '../config/constants';

const RealEstateList: React.FC = () => {
  const [estates, setEstates] = useState<RealEstate[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>('');

  const fetchEstates = async () => {
    try {
      const response = await fetch(API_CONFIG.GOOGLE_SCRIPT_URL, {
        method: 'GET',
        mode: 'no-cors' // 改為 no-cors 模式
      });

      // 由於使用 no-cors，我們需要直接訪問 API URL
      const jsonUrl = `${API_CONFIG.GOOGLE_SCRIPT_URL}?callback=?`;
      const result = await fetch(jsonUrl)
        .then(res => res.json())
        .catch(() => ({ status: 'error', message: '資料格式錯誤' }));

      if (result.status === 'success') {
        setEstates(result.data);
        setError('');
      } else {
        throw new Error(result.message || '資料格式錯誤');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('資料讀取發生錯誤，請稍後再試');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEstates();
    
    const interval = setInterval(fetchEstates, API_CONFIG.REFRESH_INTERVAL);
    
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center p-4">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-gray-200 border-t-blue-500"></div>
        <span className="ml-3">載入中...</span>
      </div>
    );
  }

  if (error) {
    return <div className="text-center p-4 text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <Helmet>
        <title>房地產清單 - 投資機會</title>
      </Helmet>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">房地產投資標的清單</h1>
        <div className="text-sm text-gray-500">
          {estates.length} 筆資料
        </div>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {estates.map((estate) => (
          <div key={estate.id} className="border rounded-lg p-4 shadow hover:shadow-lg transition-shadow">
            <h2 className="text-xl font-semibold">{estate.area}</h2>
            {/* <p className="text-gray-600 mb-2">{estate.description}</p> */}
            <div className="space-y-1">
              <p className="font-semibold">
                投資金額: <span className="text-blue-600">{estate.investmentAmount.toLocaleString()} 元</span>
              </p>
              <p className="font-semibold">
                年化報酬: <span className="text-green-600">{estate.annualReturn}%</span>
              </p>
              <p>地址: {estate.address}</p>
              <p>類型: {estate.propertyType}</p>
              <p>屋齡: {estate.buildingAge} 年</p>
              <p>樓高: {estate.floors} 樓</p>
              <p>車位: {estate.parking ? '有' : '無'}</p>
              <p>建物坪數: {estate.buildingSize} 坪</p>
              {/* <p>土地坪數: {estate.landSize} 坪</p> */}
              <p>擔保品持有類型: {estate.collateralType}</p>
              <p>擔保品持份比例: {estate.collateralRatio}%</p>
              <p className="font-semibold">
                估價: <span className="text-purple-600">{estate.appraisalValue.toLocaleString()} 元</span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RealEstateList;
