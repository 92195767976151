import React from 'react';
import BlogList from './BlogList';
import { Helmet } from 'react-helmet-async';

const Home: React.FC = () => {
  return (
    <div className="container mx-auto p-4">
      <Helmet>
        <title>🐣金雞母房地產金融平台 媒合賺取穩定的房地產收益</title>
      </Helmet>
      <BlogList />
    </div>
  );
};

export default Home; 