import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { BlogPost } from '../types/BlogPost';
import { blogService } from '../services/blogService';
import { Helmet } from 'react-helmet-async';
import { ROUTES } from '../config/constants'

const BlogPostPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<BlogPost | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        if (slug) {
          const fetchedPost = await blogService.getPostBySlug(slug);
          setPost(fetchedPost || null);
        }
      } catch (error) {
        console.error('Failed to fetch post:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  const removeFrontMatter = (content: string) => {
    // 移除開頭的 YAML frontmatter
    return content.replace(/^---[\s\S]*?---\n/, '');
  };

  if (loading) {
    return <div className="text-center py-8">載入中...</div>;
  }

  if (!post) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="text-center">
          <h1 className="text-2xl font-bold mb-4">找不到文章</h1>
          <Link to="/" className="text-blue-600 hover:text-blue-800">
            返回首頁
          </Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{post.title} - 房地產金融平台</title>
        <meta name="description" content={post.excerpt || post.description} />
        <meta name="keywords" content={post.tags.join(',')} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.description} />
        <meta property="og:type" content="article" />
        <meta property="article:published_time" content={post.publishDate} />
        <meta property="article:author" content={post.author} />
        {post.tags.map(tag => (
          <meta property="article:tag" content={tag} key={tag} />
        ))}
      </Helmet>

      <article className="container mx-auto px-4 py-8 max-w-4xl">
        <div className="mb-8">
          <Link to="/blogs" className="text-blue-600 hover:text-blue-800">
            ← 返回文章列表
          </Link>
        </div>
        
        {post.coverImage && (
          <img
            src={post.coverImage.src}
            alt={post.coverImage.alt}
            className="w-full h-64 object-cover rounded-lg mb-8"
          />
        )}

        <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
        
        <div className="flex items-center text-gray-600 mb-8">
          <span>{post.author}</span>
          <span className="mx-2">•</span>
          <time dateTime={post.publishDate}>
            {new Date(post.publishDate).toLocaleDateString('zh-TW')}
          </time>
        </div>

        <div className="prose prose-lg max-w-none">
          {post.excerpt && (
            <div className="text-xl text-gray-600 mb-8 font-medium">
              {post.excerpt}
            </div>
          )}
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            className="markdown-body"
          >
            {removeFrontMatter(post.content)}
          </ReactMarkdown>
        </div>

        <div className="mt-8 pt-8 border-t">
          <div className="flex flex-wrap gap-2">
            {post.tags.map(tag => (
              <span
                key={tag}
                className="px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm"
              >
                {tag}
              </span>
            ))}
          </div>
        </div>

        <div className="mt-8 flex gap-4 justify-center">
          <a 
            href={ROUTES.INVEST}
            className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            我要投資
          </a>
          <a
            href={ROUTES.LOAN}
            className="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
          >
            我要借貸
          </a>
        </div>
      </article>
    </>
  );
};

export default BlogPostPage;
