import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from '../config/routes';

const Navigation: React.FC = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="bg-gray-800 text-white">
      <div className="container mx-auto px-4">
        {/* 桌面版導航 */}
        <div className="hidden md:flex justify-between items-center h-16">
          <Link to={ROUTES.HOME} className="text-xl font-bold">
          🐣金雞母房地產金融平台
          </Link>
          <div className="space-x-4">
            <Link
              to={ROUTES.INVEST}
              className={`px-4 py-2 rounded ${
                location.pathname === ROUTES.INVEST
                  ? 'bg-blue-600'
                  : 'hover:bg-gray-700'
              }`}
            >
              我要投資
            </Link>
            <Link
              to={ROUTES.LOAN}
              className={`px-4 py-2 rounded ${
                location.pathname === ROUTES.LOAN
                  ? 'bg-blue-600'
                  : 'hover:bg-gray-700'
              }`}
            >
              我要借貸
            </Link>
          </div>
        </div>

        {/* 手機版導航 */}
        <div className="md:hidden">
          <div className="flex justify-between items-center h-14">
            <Link to={ROUTES.HOME} className="text-lg font-bold">
              房地產金融平台
            </Link>
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-2 rounded hover:bg-gray-700"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                {isMenuOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
          </div>

          {/* 手機版選單 */}
          {isMenuOpen && (
            <div className="py-2 space-y-1">
              <Link
                to={ROUTES.INVEST}
                className={`block px-4 py-2 rounded ${
                  location.pathname === ROUTES.INVEST
                    ? 'bg-blue-600'
                    : 'hover:bg-gray-700'
                }`}
                onClick={() => setIsMenuOpen(false)}
              >
                我要投資
              </Link>
              <Link
                to={ROUTES.LOAN}
                className={`block px-4 py-2 rounded ${
                  location.pathname === ROUTES.LOAN
                    ? 'bg-blue-600'
                    : 'hover:bg-gray-700'
                }`}
                onClick={() => setIsMenuOpen(false)}
              >
                我要借貸
              </Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
