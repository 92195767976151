const getApiUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    return '開發環境 URL';
  }
  if (process.env.NODE_ENV === 'test') {
    return '測試環境 URL';
  }
  return '正式環境 URL';
};

export const API_CONFIG = {
  GOOGLE_SCRIPT_URL: 'https://script.google.com/macros/s/AKfycbyO5phb1vLpFuvoVDvHoxOecytuyqtGtpgICGWD_EvA1q7TmjHBcQ9mdkfC-IbXCpBo/exec',
  REFRESH_INTERVAL: 5000
};

export const API_HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

export const ROUTES = {
  INVEST: '/invest',
  LOAN: '/loan'
} as const
